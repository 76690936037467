import React, { Component } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import "../../firebase"; // Adjust the path to your firebase configuration

interface LoginProps {
  email: string;
  password: string; 
  handleHomeState?:(isAuthenticated:boolean, expirationTime:number)=>void;
}

interface LoginState {
  email: string;
  password: string;
}

class Login extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      email: props.email || "",
      password: props.password || "",
    };
  }

 private handleLogin = async () => {
  
    const exp = localStorage.getItem('expirationTime');
    const factor = 1000;
    const currentTime = Math.floor(Date.now() / factor);
    let expTime = 0;
    const timeToSendRequest = 
    this.shouldSendLoginRequest(exp ? 
    parseInt(exp, 10) : null, currentTime);

     
    if (timeToSendRequest) {
      this.handleSuccessfulLogin();
       
    } else if (expTime > currentTime) {
      if (this.props.handleHomeState) {
        this.props.handleHomeState(true, expTime);
      }
    }
  };
  private handleSuccessfulLogin = async () => {
      const { email, password } = this.state;
     const auth = getAuth();
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const token = await user.getIdToken();

    const payloadBase64 = token.split('.')[1];
    const decodedPayload = JSON.parse(atob(payloadBase64));

    const expirationTime: number = decodedPayload.exp;
    localStorage.setItem("expirationTime", expirationTime.toString());

    if (this.props.handleHomeState) {
      this.props.handleHomeState(true, expirationTime);
    }

    console.log(user);
  } catch (error) {
    console.error("Error logging in:", error);
  }
};
  private shouldSendLoginRequest = (expTime: number | null, currentTime: number): boolean => {
  if (expTime) {
    return expTime < currentTime;
  } else {
    return true;
  }
};

 

  render() {
    const { email, password } = this.state;

    return (
      <div className="container d-flex justify-content-center align-items-center min-vh-100">
        <div className="card">
          <div className="card-header d-flex justify-content-center align-items-center bg-primary text-white">Login</div>
          <div className="card-body">
            <form className="form">
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  value={email}
                  onChange={(event) => {
                    this.setState({ email: event.target.value });
                  }}
                />
              </div>
              <div className="form-group">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  value={password}
                  onChange={(event) => {
                    this.setState({ password: event.target.value });
                  }}
                />
                <div className="form-group">
                  <button type="button"
                    className="btn-primary btn"
                    onClick={this.handleLogin}
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
