 
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import 'firebase/auth';
 
const firebaseConfig = {
  apiKey: "AIzaSyBKjC1Pwg0iicYKwwyHnA5V0AywUyNKEcI",
  authDomain: "authentication-906be.firebaseapp.com",
  projectId: "authentication-906be",
  storageBucket: "authentication-906be.appspot.com",
  messagingSenderId: "81865138417",
  appId: "1:81865138417:web:217618cec13b5c7a224950",
  measurementId: "G-3DGE9LP8DB"
};

 
const firebase = initializeApp(firebaseConfig);
export default firebase;
 
