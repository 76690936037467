 
import React, { Component } from "react";
 
interface AuthenticationData {
  authenticationtable: AuthenticationEntry[];
  roles: RoleEntry[];
}

interface AuthenticationEntry {
  authenticationtable_id: number;
  year: number;
  month: string;
  username: string;
  password: string;
  email: string;
  created_at: string;
  time_stamp_at: string;
  updated_at: string;
  time_stamp_updated_at: string;
}

interface RoleEntry {
  roles_id: number;
  year: number;
  month: string;
  role: string;
  created_at: string;
  time_stamp_at: string;
  updated_at: string;
  time_stamp_updated_at: string;
}

 

 

interface ManageRolesProps {
    username: string;
    email: string;
    role:string;
    roles?:any[];
     
}
interface formatedUser{
    username: string;
    email: string;
    role: string;
    roleId:number;
    authenticationTableId:number;
    selected:boolean;

}
interface ManageRolesState {
  users:formatedUser[];
   error:boolean;
   errorMessage:string;
   isSelectAll:boolean;
   searchQuery:string;
  newRole:number;
  filteredUsers:formatedUser[];
  storedUsers:formatedUser[];
  isUpdated:boolean;
  userExistsAt:number;
 
}
interface Role {
  roles_id: number;
  year: number;
  month: string;
  role: string;
  created_at: string;
  time_stamp_at: string;
  updated_at: string;
  time_stamp_updated_at: string;
}

interface User {
  authenticationtable_id: number;
  year: number;
  month: string;
  username: string;
  password: string;
  email: string;
  created_at: string;
  time_stamp_at: string;
  updated_at: string;
  time_stamp_updated_at: string;
  roles: Role[];
}

interface Conditions{
  column:string;
  value:string|number;
  operator:string;
}
interface Data{
  column:string;
  value:string|number;
}
interface RecorsToUpdate{
  conditions:Conditions[][];
  meta_data:Data[][];
}
class ManageRoles extends Component<ManageRolesProps, ManageRolesState> {
  private records:RecorsToUpdate={conditions:[], meta_data:[]};
 
  private defaultRole=3;
  private roles:Role[]=[];
  private newROle =3;;
  constructor(props: ManageRolesProps) {
    super(props);
    this.state = {
      users: [],
      filteredUsers:[],
      isSelectAll:false,
      newRole:this.defaultRole,
      error:false,
      errorMessage:"",
      searchQuery:"",
      storedUsers:[],
      isUpdated:false,
      userExistsAt:0
     
    };
  }

  componentDidMount(): void {
    const errorMessage= "something went wrong"
    const url = 'https://christsmissionchurchnetwork.org/api/authenticate/users/';
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    const roleUrl= 'https://christsmissionchurchnetwork.org/api/authenticate/users/?tableName=roles&isSingleTable=true'
    fetch(roleUrl, {method:'GET', headers:headers}).then(response=>{
    if(!response.ok){
        this.setState({error:true,errorMessage:errorMessage});
        alert(this.state.errorMessage);
    }
    return response.json()
    }).then((data:Role[])=>{
       this.roles = data;
    }
   )
  this.fetchData();
  }
  private fetchData=()=>{
    const errorMessage= "something went wrong"
      const url = 'https://api.christsmissionchurchnetwork.org/authenticate/users/';
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
  
    fetch(url, { method: 'GET', headers: headers})
      .then(response => {
        if (!response.ok) {
           this.setState({error:true,errorMessage:errorMessage});
            alert(this.state.errorMessage);
        }
        return response.json();
      })
      .then((data: AuthenticationData[]) => {
        
        const filteredUsers = data.map(authenticationtable => ({
          username: authenticationtable.authenticationtable[0].username,
          email:  authenticationtable.authenticationtable[0].email,
          role: authenticationtable.roles[0].role,
          authenticationTableId: authenticationtable.authenticationtable[0].authenticationtable_id,
          roleId:authenticationtable.roles[0].roles_id,
          selected:false
        }));
        this.setState({ users: filteredUsers ,storedUsers:filteredUsers});
      })
      .catch(error => {
        console.error(`An error occurred: ${error}`);
      });
  }
  
  private changeRole = () => {
  const url = 'https://api.christsmissionchurchnetwork.org/authenticate/update/';
  const size = 0;
  if (this.records.conditions.length === size) {
    alert("Select a user to change role");
    return;
  }
  const parentTableOne = "authenticationtable";
  const parentTableTwo = "roles";
  const relationshipType = "manytomany";
  const records = this.records;
  let headers = new Headers();

  

  headers.append('Content-Type', 'application/json');

  const data = {
    parentTableOne: parentTableOne,
    parentTableTwo: parentTableTwo,
    relationshipType: relationshipType,
    
    meta_data: {
      data: records.meta_data,
      conditions: records.conditions
    },
    multiRowMultiValueUpdate: true
  };
  
  console.log(JSON.stringify(data));

  fetch(url, {
    method: "PUT",
    headers: headers,
    body: JSON.stringify(data) // Convert data object to JSON string
  })
    .then(response => response.json())
    .then(data => {
      console.log(data);
     
      this.setState({ isSelectAll:false}, ()=>{this.records={conditions:[], meta_data:[]}
       this.fetchData();

      });  
    })
    .catch(error => {
      console.error(`An error occurred: ${error}`);
    });
}



  
private addOrRemovesUser = (index: number) => {
  const user = this.state.storedUsers[index];
 
  
  const  amount =1
  const newCondition = [
    { column: "authenticationtable_id", value: user.authenticationTableId, operator: "eq" },
    { column: "roles_id", value: user.roleId, operator: "eq" }
  ];
  const newData = { column: "roles_id", value: this.newROle};

  const userExistsAt = this.userExists(index);
  console.log(`the index user is at ${userExistsAt}`);
  console.log(`the index of the user is ${index}`);

   if(userExistsAt!== -1){
  this.records.conditions.splice(userExistsAt,amount);
  this.records.meta_data.splice(userExistsAt, amount);
   }else{
     this.records.conditions.push(newCondition);
   this.records.meta_data.push([newData]);
   }
  
 console.log(`the length is from indexeduser ${this.records.conditions.length}`);
  console.log(this.records);
};

private userExists = (index: number): number=> {
  const { storedUsers } = this.state;
  const user = storedUsers[index];
   

  for (let i = 0; i < this.records.conditions.length; i++) {
    const conditionGroup = this.records.conditions[i];
    const existingIndex = conditionGroup.findIndex(
      (condition) =>
        condition.column === "authenticationtable_id" && condition.value === user.authenticationTableId
    );

    if (existingIndex !== -1) {
     
      return i;  
    }
  }

  return -1;  
};

private handleSelectAll = () => {
  const {storedUsers, isSelectAll} = this.state;
      
  
   
  if (this.records.conditions.length !== 0) {
    this.records.conditions = [];
    this.records.meta_data = [];
  }

  
  const updatedToUsers = storedUsers.map(user => ({ ...user, selected: !isSelectAll }));
  this.setState({ storedUsers: updatedToUsers, isSelectAll: !isSelectAll });

   
  if (this.records.conditions.length === 0) {
    updatedToUsers.forEach((user, index) => {
      if (user.selected) {
        this.addOrRemovesUser (index);
      }
    });
  }
  
  console.log(`the length is from select all ${this.records.conditions.length}`);
};


 private handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
  const { checked } = event.target;
  const updatedUsers = [...this.state.storedUsers];
  const userToUpdate = { ...updatedUsers[index], selected: checked };
  updatedUsers[index] = userToUpdate;
  console.log(`the check is ${checked}`);
  
  this.setState({ storedUsers: updatedUsers, isSelectAll: false }, () => {
    this.addOrRemovesUser (index); // Perform the action inside the setState callback
  });
};


private handleSearch=(searchText:string)=>{
  const {users}= this.state;
 const  lowercaseText =  searchText.toLowerCase();
 
 
 const  filteredUsers= users.filter(user=>{
    return (user.username.toLowerCase().includes(lowercaseText)||user.email.toLowerCase().includes(lowercaseText))
  });
  if(filteredUsers.length!==0){
this.setState({storedUsers:filteredUsers});
  }else{
    this.setState({storedUsers:users});
  }
  
  console.log(`the length of the filterd list is ${filteredUsers.length} `);
 
       
}
private assignNewRole=(roleId:number)=>{
  this.newROle = roleId;
    //this.setState({newRole:roleId});
}
private future=()=>{
  alert("comming soon");
}
   render() {
  const { storedUsers} = this.state;
 
 
  return (
    <div className="container">
      
      <div className="d-flex justify-content-between align-items-center mt-3">
        <div className="row">
    <div className="col-md-2">
      <label className="ms-2  mt-3">
        <input
          type="checkbox"
          placeholder=""
          checked={this.state.isSelectAll}
          onChange={this.handleSelectAll}
        />{" "}
        Select All
      </label>
    </div>
    <div className="col-md-2">
      <select className="form-control  mt-3"
        onChange={(event) => this.assignNewRole(parseInt(event.target.value))}
      >
      
        
        <option value="">select role</option>
        {this.roles.map(role => (
          <option key={role.roles_id} value={role.roles_id}   >
            {role.role}
          </option>
        ))}
      </select>
    </div>
    <div className="col-md-3">
      <input
        type="text"
        placeholder="search name or email"
        className="form-control  mt-3"
        onChange={(event)=>this.handleSearch(event.target.value)}
      />
    </div>
    <div className="col-md-2">
      <button className="btn btn-primary me-2  mt-3" onClick={this.future}>Add User</button>
    </div>
    <div className="col-md-3">
      <button
        className="btn btn-success  mt-3"
        onClick={this.changeRole}
      >
        Change Roles
      </button>
    </div>
  </div>

        
        
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Select</th>
            <th>Name</th>
            <th>Email</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {storedUsers.map((user, index) => (
            <tr key={user.email}>
              <td>
                <input
                  type="checkbox"
                  checked={user.selected}
                  value={index}
                  
                  onChange={(event) => this.handleCheckboxChange(event ,index)}
                />
              </td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.role}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

  
  
  
}

export default ManageRoles;
