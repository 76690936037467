import React from "react";
import logo from '../../../src/logo.png';
import './../header.components/header.scss';
import logout from "../../../src/logout_FILL1_wght400_GRAD0_opsz48.svg";
import { getAuth, signOut } from "firebase/auth";

class Header extends React.Component {
  private readonly logoWidth = "80px";

  handleLogout = () => {
    console.log(localStorage.getItem("expirationTime"));
    try {
      
      localStorage.removeItem("expirationTime");
      
     
      const auth = getAuth();
      signOut(auth);

    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-primary">
        <a className="navbar-brand" href="/" >
          <img src={logo} alt="Logo" className="rounded-circle Logo" style={{ width: this.logoWidth }}  />
        </a>
        <div className="ms-auto">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a href="/" className="nav-link" onClick={this.handleLogout}>
                <img src={logout} alt="Logout" className="logout-icon" />
              </a>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Header;
