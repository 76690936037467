// src/App.tsx

import React from 'react';
import './App.scss';
import Header from './components/header.components/Header';
import Login from './components/login.component/Login';
import HomeScreen from './components/home.component/Home';
 

class App extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <HomeScreen/>
      </div>
    );
  }
}

export default App;
