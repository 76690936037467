import React, { Component } from "react";
import ManageRoles from "../manage.roles.component/ManageRoles";
import Login from "../login.component/Login";

interface HomeState {
  isAuthenticated: boolean;
  expirationTime: number;
}

interface HomeProps {}

class HomeScreen extends Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);
    this.state = {
      isAuthenticated: false,
      expirationTime: parseInt(localStorage.getItem("expirationTime") || "0", 10),
    };
  }

  componentDidMount() {
    this.checkAuthentication();
    setInterval(this.checkAuthentication, 1000); // Check every second
  }

  checkAuthentication = () => {
    const currentUnixTimestamp = this.getCurrentUnixTimestampInSeconds();
    const { expirationTime } = this.state;

    if (expirationTime > currentUnixTimestamp) {
      this.setState({ isAuthenticated: true });
    } else {
      this.setState({ isAuthenticated: false });
    }
  };

  render() {
    const { isAuthenticated } = this.state;

    return isAuthenticated ? (
      <ManageRoles email="" username="" role="" />
    ) : (
      <Login email="" password="" handleHomeState={this.handleHomeState} />
    );
  }

  private getCurrentUnixTimestampInSeconds = () => {
    const factor = 1000;
    return Math.floor(Date.now() / factor);
  };

  private handleHomeState = (isAuthenticated: boolean, expirationTime: number) => {
    this.setState({ isAuthenticated, expirationTime });
  };
}

export default HomeScreen;
